<template>
    <modal ref="modalAceptarResidente" titulo="Aceptar residente" tamano="modal-lg" aceptar @aceptar="aceptarResidente">
        <div class="col-12">
            <div class="row mx-0">
                <div class="col-12">
                    <p class="f-14 f-600 text-5d mx-4">¿Desea aceptar a este usuario como parte de la vivienda y del conjunto?</p>
                    <p class="f-14 mx-5 my-2 text-dark">{{ vivienda }}</p>
                </div>
            </div>
            <div class="row mx-0">
                <div class="col-12">
                    <p class="f-15 mx-4 pt-3">Seleccione las funciones que podrá tener</p>
                    <div class="row mx-0">
                        <div class="col-12 mx-4 pt-1">
                            <el-checkbox v-model="residente" :true-label="1" :false-label="0">
                                Funcionalidad residente
                            </el-checkbox>
                            <el-checkbox v-model="propietario" :true-label="1" :false-label="0" class="pt-2">
                                Funcionalidad propietario
                            </el-checkbox>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mx-0 pt-5">
                <div class="col-12">
                    <p class="f-14 f-600 text-5d mx-4">Existen algunos residentes en esta vivienda registrados por el administrador</p>
                    <p class="f-14 f-600 text-5d mx-4">¿Desea reemplazarlo por uno existente?</p>
                </div>
                <div class="col-12 mx-4 pt-2">
                    <div v-for="(r,k) in residentes" :key="k" class="card-user cr-pointer p-2 d-flex mb-2" :class="`${userSelected == r.id ? 'active' : ''}`" @click="selectUser(r.id)">
                        <div class="d-middle-center">
                            <div class="circle-icon-mini bg-db rounded-circle my-auto d-middle-center">
                                <i class="icon-account-edit-outline op-05 f-22" />
                            </div>
                            <div class="ml-2">
                                <p class="f-15 f-500">{{ r.nombre }} {{ r.apellido }}</p>
                                <p class="f-13">{{ r.genero }}</p> 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </modal>
</template>
<script>
import Usuarios from '~/services/usuarios'

export default {
    data(){
        return{
            asociarResidente: '',
            activo: null,
            residente: 0,
            propietario: 0,
            vivienda: '',
            idUser: null,
            residentes: [],
            userSelected: null,
        }
    },
    methods:{
        toggle(usuario){
            this.vivienda = usuario.vivienda;
            this.idUser = usuario.idUser;
            this.propietario =  usuario.propietario;
            this.residente =  usuario.residente;

            this.getResidentesVivienda(usuario.idVivienda, usuario.idUser);

            this.$refs.modalAceptarResidente.toggle()
        },
        async getResidentesVivienda(idVivienda, idUser){
            try {
                const params={
                    idVivienda : idVivienda,
                    idUser : idUser
                }

                const {data} = await Usuarios.residentesVivienda(params);
                this.residentes = data.data;

            } catch (e){
                this.errorCatch(e)
            }
        },
        selectUser(id){
            this.userSelected = id
        },
        async aceptarResidente(){
            try {
                if (this.residente === 0 && this.propietario === 0) return this.notificacion('','Por favor seleccione el tipo de residente','warning');

                let params = {
                    id_usuario: this.idUser,
                    residente: this.residente,
                    propietario: this.propietario,
                    idUserSelect: this.userSelected
                }
                const { data } = await Usuarios.aceptarResidente(params)

                this.notificacion('','Residente aceptado correctamente','success')
                this.$refs.modalAceptarResidente.toggle()
                
                this.userSelected = null
                this.propietario = 0
                this.residente = 0
                this.$emit('cargar')
            } catch (error){
                this.errorCatch(error)
            }
        },
    }
}
</script>
<style lang="scss" scoped>
   .circle-icon-mini {
        min-width: 36px;
        max-width: 36px;
        min-height: 36px;
        max-height: 36px;
    }
    .card-user{
        width: 300px;
        border-radius: 4px;
        border: 1px solid transparent;
        &.active{
            background: #93EDCF33;
            border: 1px solid #93EDCF;
        }
    }
</style>